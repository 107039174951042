import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCompanyController } from 'api/controllers/CompanyController';
import { useInvoiceController } from 'api/controllers/InvoiceController';

import {
  Company,
  Invoice,
  InvoicePatch,
  InvoiceSection,
  InvoiceStages
} from 'openapi';

import { Approvals } from 'components/forms/InvoiceVerificationForms/Approvals';
import { CreatedDocumentForm } from 'components/forms/InvoiceVerificationForms/CreatedDocumentForm';
import { InvoiceDataForm } from 'components/forms/InvoiceVerificationForms/InvoiceDataForm';
import {
  AdditionalInvoiceFields,
  ReceiverForm
} from 'components/forms/InvoiceVerificationForms/ReceiverForm';
import { SupplierForm } from 'components/forms/InvoiceVerificationForms/SupplierForm';

import { useTranslations } from 'context/TranslationContext';

import { COMPANY_PARAMETER, ID_PARAMETER } from 'utils/constants/constants';
import {
  CREATED_DOCUMENT_STEPPER_SECTIONS,
  INVOICE_STEPPER_SECTIONS
} from 'utils/constants/invoices';
import { ActiveStep } from 'utils/enums/Invoice';
import { getInvoiceStepperActiveStep } from 'utils/helpers/invoiceHelpers';
import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';
import {
  mapInvoiceForReceiverSubmit,
  mapInvoiceForSupplierSubmit,
  mapInvoiceForInvoiceSubmit,
  mapInvoiceForSummarySubmit
} from 'utils/mappers/invoice';
import { AppRoutesEnum } from 'utils/routes';

import { isSavingAtom } from 'state/state';

import { CreateCounterpartyOverview } from '../InvoiceVerificationForms/CreateCounterpartyOverview';

interface InvoiceVerificationStepperProps {
  currentInvoice: Invoice;
  triggeredReprompt: boolean;
  setCurrentInvoice: React.Dispatch<React.SetStateAction<Invoice | null>>;
  refreshInvoice: (newCompanyId: number) => Promise<void>;
}

export const InvoiceVerificationStepper = ({
  currentInvoice,
  triggeredReprompt,
  setCurrentInvoice,
  refreshInvoice
}: InvoiceVerificationStepperProps) => {
  const navigate = useNavigate();
  const { translate } = useTranslations();
  const { getAllCompanies } = useCompanyController();
  const {
    updateInvoice,
    unverifyInvoiceById,
    approveInvoicesByIds,
    rejectInvoicesByIds,
    saveInvoiceData,
    verifyCreatedDocument,
    saveInvoiceSectionData,
    returnToValidatedById
  } = useInvoiceController();

  const isCreatedDocument = !currentInvoice.filePath;

  const [activeStep, setActiveStep] = useState(
    getInvoiceStepperActiveStep(currentInvoice)
  );
  const [allCompanies, setAllCompanies] = useState<Company[]>([]);
  const [isOnMiddleStep, setIsOnMiddleStep] = useState(false);
  const [documentFormData, setDocumentFormData] = useState<Invoice>();
  const [cpIsNotFoundInDatabase, setCpIsNotFoundInDatabase] = useState(false);
  const [isReimbursement, setIsReimbursement] = useState(
    currentInvoice.isReimbursement
  );
  const [isSaving, setIsSaving] = useAtom(isSavingAtom);

  const fetchCompanies = useCallback(async () => {
    const companies = await getAllCompanies();
    setAllCompanies(companies);
  }, [getAllCompanies]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    setActiveStep(getInvoiceStepperActiveStep(currentInvoice));
  }, [currentInvoice.stage]);

  const paramsChangeHandler = useCallback(
    (
      companyParameter: string,
      stageParameter: keyof typeof InvoiceStages,
      invoiceParameter: string
    ) => {
      navigate(
        AppRoutesEnum.INVOICE_VERIFICATION.replace(
          COMPANY_PARAMETER,
          companyParameter
        ).replace(ID_PARAMETER, invoiceParameter)
      );
    },
    [navigate]
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const updateInvoiceData = useCallback(
    async (invoiceSection: InvoiceSectionKey, requestBody: Invoice) => {
      await updateInvoice(
        Number(currentInvoice.companyId),
        Number(currentInvoice.id),
        invoiceSection,
        requestBody
      );
    },
    [currentInvoice.id, updateInvoice]
  );

  const handleVerify = useCallback(
    async (
      values: Invoice & AdditionalInvoiceFields,
      section: InvoiceSectionKey
    ) => {
      let requestBody;
      switch (section) {
        case InvoiceSection.RECEIVER:
          paramsChangeHandler(
            values.companyId!.toString(),
            currentInvoice.stage!,
            currentInvoice.id!.toString()
          );
          requestBody = mapInvoiceForReceiverSubmit(values);
          break;
        case InvoiceSection.SUPPLIER:
          requestBody = mapInvoiceForSupplierSubmit(values);
          break;
        case InvoiceSection.INVOICE_DATA:
          requestBody = mapInvoiceForInvoiceSubmit(values);
          break;
        case InvoiceSection.SUMMARY:
          requestBody = mapInvoiceForSummarySubmit(values);
          break;
        default:
          requestBody = {};
          break;
      }

      try {
        await updateInvoiceData(section, requestBody);
        if (section !== InvoiceSection.SUMMARY) {
          handleNext();
        }
        if (section === InvoiceSection.SUPPLIER) {
          setIsOnMiddleStep(false);
        }
      } finally {
        refreshInvoice(values.companyId! || Number(currentInvoice.companyId));
      }
    },
    [
      allCompanies,
      currentInvoice.id,
      currentInvoice.stage,
      paramsChangeHandler,
      currentInvoice.companyId
    ]
  );

  const saveCreatedDocumentData = useCallback(
    async (values: InvoicePatch) => {
      if (!currentInvoice.id || !currentInvoice.companyId) {
        return;
      }
      setIsSaving(true);
      try {
        const result = await saveInvoiceData(
          currentInvoice.companyId,
          currentInvoice.id,
          values
        );
        setCurrentInvoice(result);
      } finally {
        setIsSaving(false);
      }
    },
    [saveInvoiceData, currentInvoice.id, currentInvoice.companyId]
  );

  const saveInvoiceSection = useCallback(
    async (values: InvoicePatch, section: InvoiceSectionKey) => {
      if (!currentInvoice.id || !currentInvoice.companyId) {
        return;
      }
      setIsSaving(true);
      try {
        await saveInvoiceSectionData(
          currentInvoice.companyId,
          currentInvoice.id,
          section,
          values
        );
      } finally {
        setIsSaving(false);
      }
    },
    [saveInvoiceSectionData, currentInvoice.id, currentInvoice.companyId]
  );

  const handleVerifyCreatedDocument = useCallback(
    async (values: InvoicePatch) => {
      if (!currentInvoice.id || !currentInvoice.companyId) {
        return;
      }

      await verifyCreatedDocument(
        currentInvoice.companyId,
        currentInvoice.id,
        values
      );
    },
    [verifyCreatedDocument, currentInvoice.id, currentInvoice.companyId]
  );

  const handleBack = async (section: InvoiceSection) => {
    await unverifyInvoiceById(
      Number(currentInvoice.companyId),
      Number(currentInvoice.id),
      section
    );
    refreshInvoice(currentInvoice.companyId!);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleApprove = async () => {
    const result = await approveInvoicesByIds([Number(currentInvoice.id)]);
    setCurrentInvoice(result);
    if (currentInvoice.stage === InvoiceStages.VALIDATED) {
      handleNext();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReject = async () => {
    await rejectInvoicesByIds([currentInvoice.id!]);
    if (currentInvoice.stage === InvoiceStages.VALIDATED) {
      handleNext();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReturnToValidated = async () => {
    const result = await returnToValidatedById(
      Number(currentInvoice.companyId),
      Number(currentInvoice.id)
    );
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCurrentInvoice(result);
  };

  const handleApprovalsBack = useCallback(
    () => handleBack(InvoiceSection.INVOICE_DATA),
    [handleBack]
  );

  const handleCreatedDocumentBack = useCallback(
    () => handleBack(InvoiceSection.RECEIVER),
    [handleBack]
  );

  const handleGoToMiddleStep = useCallback(() => {
    setIsOnMiddleStep(true);
  }, [setIsOnMiddleStep]);

  const handleBackFromMiddleStep = useCallback(() => {
    setIsOnMiddleStep(false);
  }, [setIsOnMiddleStep]);

  const stepLabelKey = (labelKey: string) => {
    if (
      (labelKey === 'labels.counterparty' ||
        labelKey === 'labels.invoiceData') &&
      isOnMiddleStep
    ) {
      return 'labels.createCounterparty';
    }

    if (labelKey === 'labels.counterparty' && isReimbursement) {
      return 'labels.reimbursement';
    }

    return labelKey;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {(isCreatedDocument
          ? CREATED_DOCUMENT_STEPPER_SECTIONS
          : INVOICE_STEPPER_SECTIONS
        ).map((labelKey) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={labelKey} {...stepProps}>
              <StepLabel {...labelProps}>
                {translate(stepLabelKey(labelKey))}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {isCreatedDocument && activeStep === 0 && (
        <Box>
          {isOnMiddleStep ? (
            <CreateCounterpartyOverview
              currentInvoice={currentInvoice}
              counterpartyFormData={documentFormData}
              handleBack={handleBackFromMiddleStep}
              handleCreate={handleVerifyCreatedDocument}
              cpIsNotFoundInDatabase={cpIsNotFoundInDatabase}
            />
          ) : (
            <CreatedDocumentForm
              currentInvoice={currentInvoice}
              handleVerify={handleVerifyCreatedDocument}
              handleSave={saveCreatedDocumentData}
              handleGoToMiddleStep={handleGoToMiddleStep}
              setDocumentFormData={setDocumentFormData}
              setCpIsNotFoundInDatabase={setCpIsNotFoundInDatabase}
            />
          )}
        </Box>
      )}
      {!isCreatedDocument && activeStep === ActiveStep.RECEIVER && (
        <ReceiverForm
          allCompanies={allCompanies}
          currentInvoice={currentInvoice}
          handleVerify={handleVerify}
          setIsReimbursement={setIsReimbursement}
        />
      )}
      {!isCreatedDocument && activeStep === ActiveStep.SUPPLIER && (
        <Box>
          {isOnMiddleStep ? (
            <CreateCounterpartyOverview
              currentInvoice={currentInvoice}
              counterpartyFormData={documentFormData}
              handleBack={handleBackFromMiddleStep}
              handleCreate={handleVerify}
              cpIsNotFoundInDatabase={cpIsNotFoundInDatabase}
            />
          ) : (
            <SupplierForm
              currentInvoice={currentInvoice}
              handleVerify={handleVerify}
              handleBack={handleBack}
              handleGoToMiddleStep={handleGoToMiddleStep}
              saveInvoiceSection={saveInvoiceSection}
              setCounterpartyFormData={setDocumentFormData}
              setCpIsNotFoundInDatabase={setCpIsNotFoundInDatabase}
            />
          )}
        </Box>
      )}
      {!isCreatedDocument && activeStep === ActiveStep.INVOICE_DATA && (
        <InvoiceDataForm
          currentInvoice={currentInvoice}
          handleVerify={handleVerify}
          handleSaveSection={saveInvoiceSection}
          handleBack={handleBack}
          triggerReprompt={triggeredReprompt}
        />
      )}
      {(activeStep === ActiveStep.APPROVALS ||
        activeStep === ActiveStep.APPROVED ||
        activeStep === ActiveStep.PAID ||
        activeStep === ActiveStep.REJECTED) && (
        <Approvals
          currentInvoice={currentInvoice}
          handleBack={
            isCreatedDocument ? handleCreatedDocumentBack : handleApprovalsBack
          }
          handleBackFromMiddleStep={handleBackFromMiddleStep}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleReturnToValidated={handleReturnToValidated}
          handleSaveData={handleVerify}
        />
      )}
    </Box>
  );
};
