import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import { COLUMNS_DEFAULT_OPTIONS } from 'utils/constants/constants';
import { TranslateFunction } from 'utils/interfaces/Translations';

export const getBankInfoColumns = (
  isOnAllCompanies: boolean,
  translate: TranslateFunction
): GridColDef[] => {
  const allCompanyColumns = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'iban',
      headerName: translate('labels.iban'),
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      flex: 0.85
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'company',
      headerName: translate('labels.company'),
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      flex: 0.9
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'balance',
      headerName: translate('labels.balance'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.55,
      sortable: false
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'balanceUpdatedAt',
      headerName: translate('labels.lastUpdated'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.8,
      sortable: false,
      valueFormatter: (params) => {
        return params.value
          ? moment(
              Math.min(params.value, moment().toDate().getTime())
            ).fromNow()
          : '-';
      }
    }
  ] as GridColDef[];

  const columns = [
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'iban',
      headerName: translate('labels.iban'),
      align: 'left',
      headerAlign: 'left',
      sortable: false,
      flex: 0.8
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'balance',
      headerName: translate('labels.balance'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.5,
      sortable: false
    },
    {
      ...COLUMNS_DEFAULT_OPTIONS,
      field: 'balanceUpdatedAt',
      headerName: translate('labels.lastUpdated'),
      align: 'left',
      headerAlign: 'left',
      flex: 0.5,
      sortable: false,
      valueFormatter: (params) => {
        return params.value
          ? moment(
              Math.min(params.value, moment().toDate().getTime())
            ).fromNow()
          : '-';
      }
    }
  ] as GridColDef[];

  return isOnAllCompanies ? allCompanyColumns : columns;
};
