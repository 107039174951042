import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps,
  Typography
} from '@mui/material';
import { PieChart, PieValueType, cheerfulFiestaPalette } from '@mui/x-charts';
import { MakeOptional } from '@mui/x-date-pickers/internals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDashboardController } from 'api/controllers/DashboardController';

import { CounterpartyExpense } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { useYearSelector } from 'hooks/useYearSelector';

import { getFormattedChartLabel } from 'utils/helpers/chartsHelpers';
import {
  condenseData,
  dataToValueKey,
  formatOthersMoney,
  mapHighlightToItems
} from 'utils/mappers/charts';

interface ExpenseForCounterpartyProps {
  sx?: SxProps;
}

export const ExpenseForCounterparty = ({ sx }: ExpenseForCounterpartyProps) => {
  const { getCounterpartyExpenses } = useDashboardController();
  const { translate } = useTranslations();
  const { companyId } = useParams();
  const { selectedYear, setExistingYears, availableYears, setSelectedYear } =
    useYearSelector(false);

  const [expenses, setExpenses] = useState<
    (Omit<CounterpartyExpense, 'data'> & { value: number })[] | null
  >(null);

  const [othersValues, setOthersValues] = useState<number[] | null>(null);

  const getOthersLabel = useCallback(
    (location: string, label: string, isOthersLabel: boolean) => {
      if (location !== 'tooltip') {
        return isOthersLabel
          ? translate('labels.pieChartMore')
          : getFormattedChartLabel(label);
      }
      return (
        <Box key={label}>
          {isOthersLabel ? translate('labels.pieChartMore') : label}
        </Box>
      );
    },
    [translate]
  );

  const fetchExpenses = useCallback(async () => {
    const response = await getCounterpartyExpenses(
      Number(companyId),
      selectedYear
    );
    const expensesWithValue = dataToValueKey<CounterpartyExpense>(
      response.expenses
    );

    setExpenses(expensesWithValue);
    setExistingYears(response.years);
  }, [companyId, selectedYear]);

  const formattedExpenses = useMemo(() => {
    const condensedExpenses = condenseData(expenses || []);

    setOthersValues(condensedExpenses.values);

    return condensedExpenses.items.map((item, index) => ({
      ...item,
      label:
        condensedExpenses.labels && condensedExpenses.values
          ? (location: string) =>
              getOthersLabel(
                location,
                item.label,
                index === condensedExpenses.items.length - 1
              )
          : item.label
    })) as MakeOptional<PieValueType, 'id'>[];
  }, [expenses, getOthersLabel]);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  useEffect(() => {
    setExpenses(null);
    fetchExpenses();
  }, [fetchExpenses]);

  return (
    <Paper elevation={4} sx={sx}>
      <Stack height="100%">
        <Grid container justifyContent="space-between">
          <Stack justifyContent="center">
            <Typography
              sx={{ ml: 4, mb: 0, fontSize: '21px', fontWeight: 'bold' }}
              align="left"
            >
              {translate('labels.counterpartyExpense')}
            </Typography>
          </Stack>
          <Stack justifyContent="center" sx={{ mr: 2 }}>
            <Select
              value={selectedYear}
              size="small"
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Box height="90%">
          <PieChart
            colors={cheerfulFiestaPalette}
            loading={!expenses}
            sx={{
              '.MuiChartsLegend-mark': {
                rx: '50%'
              }
            }}
            series={mapHighlightToItems([
              {
                data: formattedExpenses || [],
                paddingAngle: 0.5,
                cornerRadius: 5,
                valueFormatter: formatOthersMoney(
                  othersValues,
                  formattedExpenses || []
                )
              }
            ])}
            margin={{ top: 60, right: 0, bottom: 15 }}
            slotProps={{
              legend: {
                itemGap: 5,
                markGap: 3,
                itemMarkWidth: 10,
                itemMarkHeight: 10,
                direction: 'row',
                position: { horizontal: 'middle', vertical: 'top' },
                labelStyle: {
                  fontSize: 12
                }
              },
              loadingOverlay: {
                message: translate('labels.loading')
              },
              noDataOverlay: {
                message: translate('labels.noData')
              },
              popper: {
                sx: {
                  '.MuiChartsTooltip-valueCell': {
                    wordBreak: 'normal',
                    textAlign: 'right',
                    width: '1rem'
                  }
                }
              }
            }}
            tooltip={{ trigger: 'item' }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
