import {
  Stack,
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
  SelectChangeEvent,
  SxProps,
  Paper
} from '@mui/material';
import { BarChart, cheerfulFiestaPalette } from '@mui/x-charts';
import { useEffect, useState } from 'react';

import { useDashboardController } from 'api/controllers/DashboardController';

import { InvoicesReceived } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { useYearSelector } from 'hooks/useYearSelector';

import { mapHighlightToItems } from 'utils/mappers/charts';

interface ReceivedInvoicesProps {
  sx?: SxProps;
}

export const ReceivedInvoices = ({ sx }: ReceivedInvoicesProps) => {
  const { translate } = useTranslations();
  const { getInvoicesReceived } = useDashboardController();
  const { availableYears, selectedYear, setSelectedYear, setExistingYears } =
    useYearSelector(false);

  const [invoicesReceived, setInvoicesReceived] = useState<
    InvoicesReceived[] | null
  >(null);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  const fetchInvoicesReceived = async () => {
    const response = await getInvoicesReceived(selectedYear);
    setExistingYears(response.years);
    setInvoicesReceived(response.invoicesCounts);
  };

  useEffect(() => {
    setInvoicesReceived(null);
    fetchInvoicesReceived();
  }, [selectedYear]);

  return (
    <Paper elevation={4} sx={sx}>
      <Stack height="100%">
        <Grid container justifyContent="space-between">
          <Stack justifyContent="center">
            <Typography
              sx={{ ml: 4, mb: 0, fontSize: '21px', fontWeight: 'bold' }}
              align="left"
            >
              {translate('labels.invoicesReceived')}
            </Typography>
          </Stack>
          <Stack justifyContent="center" sx={{ mr: 2 }}>
            <Select
              value={selectedYear}
              size="small"
              onChange={handleYearChange}
            >
              {availableYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Box height="90%">
          <BarChart
            loading={!invoicesReceived}
            dataset={invoicesReceived || []}
            series={mapHighlightToItems([
              {
                dataKey: 'data',
                label: translate('labels.invoicesReceived')
              }
            ])}
            layout="horizontal"
            yAxis={[
              {
                scaleType: 'band',
                tickLabelPlacement: 'middle',
                tickPlacement: 'middle',
                dataKey: 'label'
              }
            ]}
            xAxis={[
              {
                tickMinStep: 1
              }
            ]}
            colors={cheerfulFiestaPalette}
            slotProps={{
              legend: {
                hidden: true
              },
              loadingOverlay: {
                message: translate('labels.loading')
              },
              noDataOverlay: {
                message: translate('labels.noData')
              }
            }}
            margin={{ left: 150 }}
          />
        </Box>
      </Stack>
    </Paper>
  );
};
