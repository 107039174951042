import { Box, Grid, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BankInfoGrid } from 'components/BankInfoGrid/BankInfoGrid';
import { BudgetVsActual } from 'components/Charts/BudgetVsActual';
import { BudgetVsActualMonthly } from 'components/Charts/BudgetVsActualMonthly';
import { ExpenseForCounterparty } from 'components/Charts/ExpenseForCounterparty';
import { ExpensesForCompany } from 'components/Charts/ExpensesForCompany';
import { QuarterlyCompaniesExpenses } from 'components/Charts/QuarterlyCompaniesExpenses';
import { ReceivedInvoices } from 'components/Charts/ReceivedInvoices';
import { DocumentsInfoGrid } from 'components/DocumentsInfoGrid.tsx/DocumentsInfoGrid';

import { usePermissions } from 'context/PermissionsContext';

export const Dashboard = () => {
  const { companyId } = useParams();
  const { permissions } = usePermissions();

  const isAllCompanies = useMemo(() => !companyId, [companyId]);

  return (
    <Box height="60vw">
      {isAllCompanies ? (
        <Stack height="100%" justifyContent="space-between" spacing={1}>
          {permissions.IBANS.read && (
            <Box
              sx={{
                display: 'flex',
                height: '245px',
                justifyContent: 'space-between'
              }}
            >
              <BankInfoGrid
                sx={{ height: '100%', width: '49.5%' }}
                isOnAllCompanies={isAllCompanies}
              />

              <DocumentsInfoGrid
                sx={{ height: '100%', width: '49.5%' }}
                isOnAllCompanies={isAllCompanies}
              />
            </Box>
          )}
          <Grid
            container
            height="100%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <QuarterlyCompaniesExpenses
              sx={{
                height: '49.5%',
                width: '100%',
                pt: 2
              }}
            />
            <ReceivedInvoices
              sx={{
                height: '49.5%',
                width: '100%',
                pt: 2
              }}
            />
          </Grid>
        </Stack>
      ) : (
        <Stack height="100%" justifyContent="space-between" spacing={1}>
          {permissions.IBANS.read && (
            <Box
              sx={{
                display: 'flex',
                height: '245px',
                justifyContent: 'space-between'
              }}
            >
              <BankInfoGrid
                sx={{ height: '100%', width: '49.5%' }}
                isOnAllCompanies={isAllCompanies}
              />

              <DocumentsInfoGrid
                sx={{ height: '100%', width: '49.5%' }}
                isOnAllCompanies={isAllCompanies}
              />
            </Box>
          )}
          <Grid
            container
            height={permissions.IBANS.read ? '36%' : '50%'}
            justifyContent="space-between"
          >
            <ExpensesForCompany
              sx={{ height: '100%', width: '49.5%', pt: 2 }}
            />
            <ExpenseForCounterparty
              sx={{ height: '100%', width: '49.5%', pt: 2 }}
            />
          </Grid>
          <Grid
            container
            height={permissions.IBANS.read ? '36%' : '50%'}
            justifyContent="space-between"
          >
            <BudgetVsActual sx={{ height: '100%', width: '49.5%', pt: 2 }} />
            <BudgetVsActualMonthly
              sx={{ height: '100%', width: '49.5%', pt: 2 }}
            />
          </Grid>
        </Stack>
      )}
    </Box>
  );
};
